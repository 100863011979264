angular.module('fingerink')
    .directive('uploadDocument', function () {
        return {
            restrict: 'E',
            replace: false,
            templateUrl: 'directives/uploadDocument.tpl.html',
            scope: {
                template: '='
            },
            controllerAs: 'controller',
            controller: 'sendDashboardWidgetCtrl'
        };
    })


    .service('uploadDocumentModal', function ($uibModal,session,  $rootScope) {
        this.modalInstance = null;
        this.openModal = function () {
            var credits = session.getRemainingCredits();
            var scope =  $rootScope.$new(true);
            scope.credits= credits.credits - credits.usedCredits;
            scope.stateGo= 'main.send';

            this.modalInstance = $uibModal.open({
                backdrop: 'static',
                templateUrl: 'directives/uploadDocumentModal.tpl.html',
                controller: 'sendDashboardWidgetCtrl',
                controllerAs: 'controller',
                animation: false,
                size: 'lg',
                scope: scope
            });
            return this.modalInstance;
        };
    })

    .controller('sendDashboardWidgetCtrl', function ($rootScope, $scope, $timeout, session, signatureService, $state, $translate, ckEditorModal, selectTemplateModal, sendSelectTypeModal, swalService) {
        var that = this;
        that.user = session.get().user;
        that.close = () => $scope.$dismiss('cancel');
       that.credits = session.getRemainingCredits();
        var continueSend = (document) => {
            if ($scope.$dismiss) {
                $scope.$dismiss('cancel');
            }
            that.uploadingDocument = false;
            sendSelectTypeModal.openModal().result.then((r) => r?$state.go($scope.template?'main.createTemplate':'main.send', { document: document.id, type: r }):that.close());
        };


        ///////////////////////////////
        // Dropbox                  //
        //////////////////////////////
        that.dropifyLoaded = false;
        that.dropboxChoose = function () {
            that.uploadingDocument = true;
            Dropbox.choose({
                success: function (files) {
                    that.nombreDocumento = files[0].name;
                    that.dropifyLoaded = false;
                    signatureService.createFirmaDraftFromURL({
                        subject: files[0].name,
                        server: 'DROPBOX',
                        url: files[0].link
                    }).then(
                        (response) => continueSend(response.data),
                        () => swalService.basicSwal("Algo ha ido mal", "Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante", "error")
                    );
                },
                cancel: function () { },
                linkType: "direct",
                multiselect: false,
                extensions: ['.pdf']
            });
        };

        ///////////////////////////////
        // Drive                     //
        //////////////////////////////
        that.DriveOnPicked = function (docs) {


            that.uploadingDocument = true;

            var request = gapi.client.drive.files.get({
                'fileId': docs[0].id
            });
            request.execute(function (resp) {
                signatureService.createFirmaDraftFromURL({
                    subject: docs[0].name,
                    server: 'DROPBOX',
                    url: 'https://docs.google.com/document/d/' + docs[0].id + '/export?format=pdf', //resp.downloadUrl,//'https://www.googleapis.com/drive/v3/files/' + docs[0].id + '?alt=media',
                    auth: gapi.auth.getToken().access_token
                }).then(
                    response => continueSend(response.data),
                    () => swalService.basicSwal("Algo ha ido mal", "Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante", "error"));
            });
        };



        ///////////////////////////////
        // One drive                 //
        //////////////////////////////
        that.OneDrivedriveSuccess = function (files) {
            that.uploadingDocument = true;

            signatureService.createFirmaDraftFromURL({
                subject: files.value[0].name,
                server: 'DROPBOX',
                url: files.value[0]['@microsoft.graph.downloadUrl']
            }).then(
                (response) => continueSend(response.data) /*$state.go("main.send", { document: response.data.id })*/,
                () => swal($translate.instant("Algo ha ido mal"), $translate.instant("Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante"), "error")
            );
        };

        ///////////////////////////////
        // Cargar arrastrar fichero  //
        //////////////////////////////
        $scope.$watch('controller.files', function (files) {
            if (files && files[0]) {
                that.nombreDocumento = files[0].name;
                that.dropifyLoaded = false;
                that.uploadingDocument = true;

                signatureService.createFirmaDraft(files[0], {
                    subject: files[0].name
                }).then(function (response) {
                    continueSend(response.data);
                    that.dropifyLoaded = true;
                    /*
                    $state.go("main.send", {
                        document: response.data.id
                    });*/
                }, function () {
                    that.dropifyLoaded = true;
                    swalService.basicSwal("Algo ha ido mal", "Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante", "error");
                    angular.element('.dropify-clear').triggerHandler('click');
                });
            }
        });

        $timeout(function () {
            that.dropifyLoaded = true;
            $('.dropify').dropify({
                messages: {
                    'default': $translate.instant('Arrastrar archivos o click aquí para subir'),
                    'replace': $translate.instant('DROPS OR CLICK HERE TO REPLACE DOCUMENT'),
                    'remove': $translate.instant('REMOVE'),
                    'error': $translate.instant('Ha ocurrido algún error. Inténtelo de nuevo.')
                },
                tpl: {
                    wrap: '<div class="dropify-wrapper"></div>',
                    loader: ' ',
                    message: '<div class="dropify-message"> <p>{{ default }}</p></br></div>',
                    preview: ' ',
                    filename: '<p class="dropify-filename"><span class="file-icon"></span> <span class="dropify-filename-inner"></span></p>',
                    clearButton: '<button type="button" class="dropify-clear">{{ remove }}</button>',
                    errorLine: '<p class="dropify-error">{{ error }}</p>',
                    errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
                }
            });
        }, 100);



        that.openCkeditor = function () {
            ckEditorModal.openModal().result.then(function (result) {
                if (result) {
                    that.uploadingDocument=true;
                    signatureService.createFirmaDraftFromHTML({
                        subject: $translate.instant("Documento creado"),
                        html: result
                    }).then(
                        (response) => continueSend(response.data), //$state.go("main.send", { document: response.data.id }),
                        () => swalService.basicSwal("Algo ha ido mal", "Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante", "error")
                    );
                }
            });
        };

        that.openTemplates = () => selectTemplateModal.openModal('Advanced').result.then((result) => result?$state.go("main.send", { template: result.id }):null);
    });
